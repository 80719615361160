<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="报价单号">
              <el-input v-model="form.offerCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="style">
              <el-select
                v-model="form.style"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select
                v-model="form.vendorId"
                clearable
                filterable
                multiple
                collapse-tags
                id:placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="更新人">
              <el-input v-model="form.updateUser" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="vendorOfferPoLoading" @click="queryClick(1)">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <ImportButton export-key="scm_vendor_offer_po_detail" :params="vendorOfferPoParams" :export-name="'导出更新采购订单明细'" />
    <el-table
      ref="multipleTable"
      :loading="vendorOfferPoLoading"
      class="mb-3 mt-3"
      :data="tableDatas"
      max-height="550px"
      :header-cell-style="{background:'#fafafa'}"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="left"
        prop="offerCode"
        label="报价单号"
        min-width="140"
      />

      <el-table-column
        prop="style"
        label="Style"
        align="center"
        min-width="160"
      />
      <el-table-column
        prop="vendorName"
        label="供应商"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="oldCurrency"
        label="原报价币种"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="newCurrency"
        label="更新后报价币种"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="updateNum"
        label="更新采购订单数量"
        align="center"
        min-width="150"
      />
      <el-table-column
        prop="newTotalPrice"
        label="更新采购订单总金额"
        align="center"
        min-width="150"
      />
      <el-table-column
        prop="updateUser"
        label="更新人"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="updateTime"
        label="更新时间"
        align="center"
      />
      <el-table-column
        fixed="right"
        :label="$t('page.operate')"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="adddetail(scope.row)">
            查看详情
          </el-button>
        </template>
      </el-table-column>

    </el-table>

    <Paging :pager="pager" end @pagination="pagerUpdate" />

  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import ImportButton from '@/components/ExportFile'
import { formatNum } from '@/utils/index'

import { queryStyleList, queryVendorList, vendorOfferPo } from '@/api/scm-api'
export default {
  components: { Paging, ImportButton },

  data() {
    return {
      form: {
        value1: [],
        style: '',
        vendorId: ''
      },
      vendorOptions: [],
      vendorOfferPoLoading: false,
      pager: {
        current: 1,
        size: 20,
        pages: 1
      },
      styleOptions: [],
      tableDatas: []
    }
  },
  computed: {
    vendorOfferPoParams() {
      const [startUpdateTime, endUpdateTime] = this.form.value1 || []
      const { style, vendorId } = this.form
      return Object.assign({}, this.pager, this.form, { startUpdateTime, endUpdateTime }, { style: String(style) || '', vendorId: String(vendorId) || '' })
    }

  },
  created() {

  },
  mounted() {
    this._queryStyleList()
    this._queryVendorList()
    this.queryClick(1)
  },
  methods: {
    adddetail({ id }) {
      this.$router.push({
        path: 'details',
        append: 'true',
        query: { 'id': id }
      })
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick(1)
    },
    async queryClick(flag) {
      try {
        this.vendorOfferPoLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { code, datas: { records, pager }} = await vendorOfferPo(this.vendorOfferPoParams)
        if (code === 0) {
          this.pager = pager
          this.tableDatas = records.map(item => {
            for (const i in item) {
              if (/^[0-9]+.?[0-9]*/.test(item[i])) {
                item[i] = formatNum(item[i])
              }
            }
            return item
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.vendorOfferPoLoading = false
      }
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    }
  }
}
</script>

<style scoped lang="scss">

</style>
